<template>
  <div>
    <div
      v-if="isLoaded && !error"
      id="intro_wishlist_items"
      :class="{ 'disable-whole-div': isPerformingAction }"
    >
      <div class="d-flex flex-row">
        <template v-if="hasFilterOptions || publicToken">
          <!-- Sidebar -->
          <div
            v-if="hasFilterOptions"
            class="wishlist-sidebar side-bar"
          >
            <shop-filter
              v-if="
                filterOptions.departments && filterOptions.departments.length
              "
              class="mr-1"
              :filters="filters"
              :total-products="totalProducts"
              :filter-options="filterOptions"
              :whole-sale-enable="isShowWsp"
              :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
              @update-filters="fetchShopProducts"
              @close-sidebar="closeSideBar"
            />
          </div>

          <!-- ECommerce Header -->
          <div class="pb-0 w-100">
            <div
              class="sticky-search-bar wishlist"
              :class="{ 'pb-0': !isBrand, 'public-full-width': !!publicToken }"
            >
              <section id="ecommerce-header">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="ecommerce-header-items">
                      <div class="result-toggler">
                        <feather-icon
                          icon="MenuIcon"
                          class="d-block d-lg-none mr-1"
                          size="21"
                          @click="mqShallShowLeftSidebar = true"
                        />
                        <div class="search-results d-block d-lg-none">
                          {{ totalProducts || 0 }} product{{
                            totalProducts > 1 ? 's' : ''
                          }}
                          found
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Overlay -->
              <div class="body-content-overlay" />

              <!-- Searchbar -->
              <div class="ecommerce-searchbar">
                <b-row class="wishlist-row ml-0 align-items-center">
                  <b-col
                    ref="searchInputBox"
                    class="pl-0 pr-0 col-12 col-md-4 h-100"
                  >
                    <b-input-group class="input-group-merge h-100">
                      <b-form-input
                        v-model="filters.search"
                        v-b-tooltip.hover.bottom="
                          showSearchBarToolTip ? searchBarPlaceholderText : ''
                        "
                        :placeholder="searchBarPlaceholderText"
                        class="search-product pr-3 h-100"
                        @input="searchProducts"
                        @focus="
                          isFocusedSearchBar = true;
                          showSearchBarToolTip = false;
                        "
                        @blur="isFocusedSearchBar = false"
                        @mouseenter="setShowTooltip()"
                      />
                    </b-input-group>
                    <feather-icon
                      icon="SearchIcon"
                      size="18"
                      class="position-absolute search-icon bg-white"
                    />
                  </b-col>
                  <b-col class="pl-0 col-6 col-md-2 h-100">
                    <div
                      v-if="
                        isNotPublicTokenWithRetailer &&
                          wishlist.availableCurrencies.length
                      "
                      class="ml-md-1 mt-1 mt-md-0"
                    >
                      <currency-dropdown
                        :base-currency="wishlistBaseCurrency"
                        :selected-currency="filters.currency"
                        :currencies="wishlist.availableCurrencies || []"
                        @on-selected-currency="filterByCurrency"
                      />
                    </div>
                  </b-col>
                  <b-col
                    class="
                      col-12
                      pt-1 pt-md-0
                      col-md-6
                      d-flex
                      justify-content-end
                      pr-50
                      h-100
                    "
                  >
                    <!-- Select all button -->
                    <div
                      v-if="!publicToken"
                      class="mr-1"
                    >
                      <select-all-button
                        :is-selected-all="isSelectedAllOnScreen"
                        @toggle-select-all="onToggleSelectAll"
                      />
                    </div>
                    <b-button
                      v-if="isBrand && !isReadOnly"
                      v-b-modal="`modal-share-wishlist-${wishlistId}`"
                      class="mr-1 elements-center py-0 text-nowrap h-100"
                      variant="success"
                      @click="onClickShareWishlist"
                    >
                      <rounded-share-icon class="share-seletion-icon" />
                      Share Selection
                    </b-button>
                    <b-button
                      class="custom-icon-btn h-100"
                      :variant="
                        itemViewClass === PRODUCTS_VIEW.GRID_VIEW
                          ? 'outline-info'
                          : 'outline-secondary'
                      "
                      @click="onCompactToggleClick"
                    >
                      <LargeGridViewIcon />
                    </b-button>
                    <b-button
                      class="custom-icon-btn mr-1 h-100"
                      :variant="
                        itemViewClass === PRODUCTS_VIEW.COMPACT_GRID_VIEW
                          ? 'outline-info'
                          : 'outline-secondary'
                      "
                      @click="onCompactToggleClick"
                    >
                      <CompactGridViewIcon />
                    </b-button>
                    <div>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :text="selectedSortBy.text"
                        right
                        variant="outline-primary"
                        class="custom-dropdown-radius h-100"
                      >
                        <b-dropdown-item
                          v-for="sortOption in sortByOptions"
                          :key="sortOption.value"
                          @click="selectedSortBy = sortOption"
                        >
                          {{ sortOption.text }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-if="!isBrand"
                  class="pl-0 col-12 mx-10-px align-items-center h-100"
                >
                  <span
                    class="color-grey-sult font-size-16-px"
                  >Group Products by:
                  </span>
                  <b-dropdown
                    variant="none"
                    class="group-dropdown"
                    no-caret
                  >
                    <template #button-content>
                      <span class="pr-2-px font-size-16-px">
                        {{ selectedGroup || 'None' }}</span>
                      <feather-icon
                        class="align-middle"
                        icon="ChevronDownIcon"
                        size="16"
                      />
                    </template>
                    <div class="heading">
                      Group By
                    </div>
                    <b-dropdown-item
                      v-for="group in groups"
                      :key="group"
                      :active="selectedGroup === group"
                      @click="selectedGroup = group"
                    >
                      {{ group }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-row>
              </div>
            </div>

            <!-- Wishlist Products -->
            <div
              v-if="products.length"
              class="shop-height"
              :class="!isBrand && 'wishlist-page'"
            >
              <div
                v-for="(groupedProduct, index) in products"
                :key="index"
              >
                <div
                  v-if="groupedProduct._id && groupedProduct.products.length"
                  class="group-heading wishlist"
                  :style="`z-index :${index + 2}`"
                >
                  <div class="name">
                    {{ groupedProduct._id }}
                  </div>
                </div>
                <section
                  v-if="groupedProduct.products.length"
                  class="wishlist-items"
                  :class="itemViewClass"
                >
                  <!-- has product -->
                  <template>
                    <b-card
                      v-for="product in groupedProduct.products"
                      :key="product.productInfo._id"
                      class="ecommerce-card ecom mb-2"
                      no-body
                    >
                      <span
                        v-if="product.productInfo.keyStyles"
                        class="text-capitalize table-status key-style"
                      >
                        {{ product.productInfo.keyStyles }}
                      </span>
                      <b-form-checkbox
                        v-if="isShowWsp && !isReadOnly"
                        :id="product.productInfo._id"
                        :checked="indexes.includes(product.productInfo._id)"
                        class="customCheck"
                        :class="show ? 'showCheckAll' : ''"
                        @change="onCheckboxChange(product, $event)"
                      />

                      <div class="item-img text-center pt-0">
                        <b-link
                          :class="show ? 'pointerevents' : ''"
                          :disabled="!!publicToken"
                          @click="onClickWishlistProduct(product)"
                        >
                          <div class="card-square-image">
                            <b-card-img-lazy
                              class="bg-gray img img-responsive fullwidth"
                              :alt="`${product.productInfo.name}-${product.productInfo._id}`"
                              fluid-grow
                              :src="parseProductImage(product.productInfo)"
                              :class="`img-${product.productInfo._id}`"
                              @load.native="
                                calculateImageRatio(
                                  `img-${product.productInfo._id}`
                                )
                              "
                              @error.native="imageLoadError"
                            />
                            <div class="product-color-wrapper">
                              <div
                                v-for="productColors in product.colors"
                                :key="productColors.color"
                                class="product-color-bg"
                              >
                                <div
                                  v-if="getColorCode(productColors.colorFamily)"
                                  v-b-tooltip.hover.left="productColors.color"
                                  class="product-color"
                                  :class="
                                    classForWhiteColor(
                                      productColors.colorFamily
                                    )
                                  "
                                  :style="
                                    'background:' +
                                      getColorCode(productColors.colorFamily)
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </b-link>
                      </div>

                      <!-- Product Details -->
                      <b-card-body class="card-body-padding">
                        <div class="product-name item-name d-flex">
                          <b-link
                            :id="`tooltip_w_product_name_${product.productInfo._id}`"
                            :class="show ? 'pointerevents' : ''"
                            class="text-left item-name pr-2"
                            :disabled="!!publicToken"
                            :to="{
                              name: 'product-detail',
                              params: {
                                id: product.productInfo._id,
                                collectionId: product.productInfo.collectionId,
                              },
                            }"
                            @mouseover="
                              showTooltip(
                                `tooltip_w_product_name_${product.productInfo._id}`,
                                product.productInfo._id
                              )
                            "
                          >
                            {{ product.productInfo.name }}
                          </b-link>
                          <b-tooltip
                            v-if="product.productInfo._id === tooltipId"
                            class="font-Inter"
                            :target="`tooltip_w_product_name_${product.productInfo._id}`"
                            placement="bottom"
                            triggers="hover"
                          >
                            {{ product.productInfo.name }}
                          </b-tooltip>
                        </div>
                        <div
                          class="
                            d-flex
                            mb-5-px
                            flex-wrap
                            justify-content-between
                          "
                        >
                          <b-card-text class="product-style-code">
                            <span class="style-code">Style Code:</span>
                            {{ product.productInfo.styleCode }}
                          </b-card-text>
                        </div>
                        <div
                          class="
                            d-flex
                            justify-content-between
                            flex-wrap
                            text-nowrap
                          "
                        >
                          <b-card-text
                            v-if="isShowWsp && !publicToken"
                            class="product-price d-flex flex-wrap"
                          >
                            WSP:
                            <span
                              v-if="!product.productInfo.discountedPrice"
                              :class="{
                                'text-line-through':
                                  product.productInfo.discountedPrice,
                              }"
                            >
                              {{
                                formatCurrency(
                                  product.productInfo.price,
                                  product.productInfo.currency
                                )
                              }}
                            </span>
                            <span
                              v-if="product.productInfo.discountedPrice"
                              class="discounted-price"
                            >
                              {{
                                formatCurrency(
                                  product.productInfo.discountedPrice,
                                  product.productInfo.currency
                                )
                              }}
                            </span>
                          </b-card-text>
                          <b-card-text
                            class="
                              product-price-rrp product-price-rrp-public
                              mb-0
                            "
                          >
                            <b-card-text
                              class="
                              product-price-rrp product-price-rrp-public
                              mb-0
                            "
                            >
                              RRP:
                              {{
                                formatCurrency(
                                  product.productInfo.retailPrice,
                                  product.productInfo.currency
                                )
                              }}
                            </b-card-text>
                          </b-card-text>
                        </div>
                      </b-card-body>

                      <!-- Action Buttons -->
                      <div
                        v-if="isShowWsp && !isReadOnly"
                        class="item-options flex-wrap text-center"
                      >
                        <b-button
                          :class="show ? 'pointerevents' : ''"
                          variant="light"
                          class="btn-wishlist remove-wishlist"
                          @click="removeProductFromWishlistClick(product)"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="18"
                          />
                          <span>Remove</span>
                        </b-button>
                        <b-button
                          v-if="isNotPublicTokenWithRetailer"
                          :class="show ? 'pointerevents' : ''"
                          variant="none"
                          class="btn-cart button-cart move-cart text-nowrap"
                          @click="
                            handleWishlistCartActionClick(product, wishlistId)
                          "
                        >
                          <feather-icon
                            icon="ShoppingCartIcon"
                            class="mr-50"
                            size="18"
                          />
                          <span class="compact-view-cart-text">{{
                            isProductInCart(product) ? 'In Cart' : ''
                          }}</span>
                          <span class="large-view-cart-text">
                            {{
                              isProductInCart(product)
                                ? 'View In Cart'
                                : 'Move to Cart'
                            }}</span>
                        </b-button>
                      </div>
                    </b-card>
                  </template>
                </section>
              </div>
              <section
                v-if="continueLoading"
                class="mt-4"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="text-center"
                  >
                    <b-spinner
                      ref="loadingIcon"
                      class="loading-icon"
                      label="Loading..."
                    />
                  </b-col>
                </b-row>
              </section>
            </div>
            <div v-else>
              <empty-products-message v-if="filters.search" />
            </div>
          </div>
        </template>
        <template v-else>
          <b-card
            title="No Products Added"
            class="w-100"
          >
            <b-card-text>
              Go to "{{
                isNotPublicTokenWithRetailer ? 'Explore' : 'Collections'
              }}" and start adding some products to your Amazing
              {{ getWishlistTitle(isNotPublicTokenWithRetailer) }}
            </b-card-text>
            <div class="d-flex">
              <div id="explore_button">
                <b-button
                  class="explore-now"
                  :to="
                    isNotPublicTokenWithRetailer
                      ? { name: 'explore' }
                      : { name: 'collections' }
                  "
                  variant="primary"
                  :disabled="
                    isNotPublicTokenWithRetailer && !isEnabledMarketplace
                  "
                >
                  {{
                    isNotPublicTokenWithRetailer ? 'Explore Now' : 'Collections'
                  }}
                </b-button>
              </div>
            </div>
            <b-tooltip
              v-if="isNotPublicTokenWithRetailer && !isEnabledMarketplace"
              target="explore_button"
              placement="bottom"
            >
              {{ marketplaceAccessDeniedMessage }}
            </b-tooltip>
          </b-card>
        </template>
      </div>
      <tool-kit :toolkit-page="TOOLKIT_PAGE.WISHLIST" />
    </div>
    <div
      v-else-if="error"
      class="d-flex justify-content-center align-items-center"
    >
      <b-card class="w-100 p-3 elements-center text-center">
        <b-card-text>
          <h3 class="error-text">
            {{ error }}
          </h3>
        </b-card-text>
      </b-card>
    </div>
    <b-card
      v-else
      class="p-3 elements-center"
    >
      <b-spinner />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardImgLazy,
  BCardText,
  BLink,
  BButton,
  BFormCheckbox,
  BSpinner,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import {
  apiToastError,
  apiToastSuccess,
  apiToastWarning,
} from '@core/utils/toast'
import { getColorCode, classForWhiteColor } from '@/product-colors'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
  debounce,
  elementInViewport,
  formatObject,
  getDefaultProductPlaceholderImage,
  getWishlistTitle,
  isEllipsisActive,
} from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics, {
  getSelectionOrWishlistMixpanelObj,
} from '@/@core/utils/analytics'
import constants, {
  TOOLKIT_PAGE,
  wishlistAction,
  PRODUCTS_VIEW,
  PRODUCTS_LIMIT,
  marketplaceAccessDeniedMessage,
  DOWNLOAD_TYPES,
  FILE_FORMATS,
  searchBarPlaceholderText,
} from '@/constants'
import LargeGridViewIcon from '@/@core/assets/svg-components/LargeGridViewIcon.vue'
import CompactGridViewIcon from '@/@core/assets/svg-components/CompactGridViewIcon.vue'
import {
  FETCH_WISHLIST_FILTERS,
  FETCH_WISHLIST_PRODUCTS,
  UPDATE_CURRENT_CHECKOUT_WISHLIST,
  UPDATE_IS_SELECTED_ALL_PRODUCTS,
  UPDATE_SELECT_COUNT,
  UPDATE_SELECT_TYPE,
  MULTI_SELECT_PAGES,
  UPDATE_TOTAL_PRODUCTS_COUNT,
  FETCH_WISHLIST_PRODUCTS_GUEST,
  FETCH_WISHLIST_FILTERS_GUEST,
} from '@/store/modules/shop.module'
import RoundedShareIcon from '@/@core/assets/svg-components/RoundedShareIcon.vue'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'

import { mapState } from 'vuex'
import { DOWNLOAD_COLLECTION } from '@/store/modules/collection.module'
import Ripple from 'vue-ripple-directive'
import CurrencyDropdown from '@/views/components/currency-dropdown/CurrencyDropdown.vue'
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'
import SelectAllButton from '@/views/components/select-all-btn/SelectAllButton.vue'
import { useSelections } from '@/@core/comp-functions/selections/useSelection'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import ShopFilter from '../shop/ShopFilter.vue'
import EmptyProductsMessage from '../shop/EmptyProductsMessage.vue'
import UserRoleMixin from '../UserRoleMixin.vue'
import { useShopFiltersSortingAndPagination } from '../shop/useECommerceShop'
import ShopMixin from '../shop/ShopMixin.vue'

const { formatCurrency } = utils

const { BRAND_ACCESS } = c

const sortByOptions = useShopFiltersSortingAndPagination().sortByOptions

const { CONNECTION_REQUEST_STATUS, TRACKS } = constants

const { MULTI_SELECT_PRODUCTS } = TRACKS.ACTIONS

export default {
  name: 'Wishlist',
  components: {
    BCard,
    BCardBody,
    BCardImgLazy,
    BCardText,
    BLink,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ShopFilter,
    FeatherIcon,
    EmptyProductsMessage,
    LargeGridViewIcon,
    CompactGridViewIcon,
    RoundedShareIcon,
    CurrencyDropdown,
    SelectAllButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin, ProductImageMixin, ShopMixin],
  props: {
    select: Boolean,
    publicToken: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      groupIndexes: [],
      selectall: 'false',
      tooltipId: '',
      getDefaultProductPlaceholderImage,
      TOOLKIT_PAGE,
      getColorCode,
      classForWhiteColor,
      getWishlistTitle,
      PRODUCTS_VIEW,
      itemViewClass: PRODUCTS_VIEW.GRID_VIEW,
      marketplaceAccessDeniedMessage,
      sortByOptions,
      selectedSortBy: sortByOptions[0],
      searchBarPlaceholderText,
      showSearchBarToolTip: true,
      isFocusedSearchBar: false,
      updatedProductsIdsSet: new Set(),
    }
  },
  computed: {
    isNotPublicTokenWithRetailer() {
      return !this.publicToken && this.isRetailer
    },
    hasFilterOptions() {
      return Object.keys(this.filterOptions)?.length > 2
    },
    isShowWsp() {
      return !(
        this.isWspHidden
        && this.requestStatus !== CONNECTION_REQUEST_STATUS.APPROVED
      )
    },
    ...mapState({
      profile: state => state.auth.profileData,
    }),
    isEnabledMarketplace() {
      return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
    },
    fetchedProductsIdsSet() {
      const productsIdsSet = new Set()
      if (!this.products) return productsIdsSet
      this.products.forEach(groupedProduct => {
        groupedProduct.products.forEach(product => {
          productsIdsSet.add(product.productId)
        })
      })
      return productsIdsSet
    },
    productList() {
      return this.products.flatMap(groupIndex => groupIndex.products.filter(product => this.indexes.includes(product.productInfo._id)))
    },
    show() {
      return this.indexes.length > 0
    },
  },
  watch: {
    selectedSortBy() {
      let sort = this.selectedSortBy.value

      if (this.publicToken && sort === 'highest') {
        sort = 'highest_retail'
      }

      if (this.publicToken && sort === 'lowest') {
        sort = 'lowest_retail'
      }

      this.filters.sort = sort
      this.fetchShopProducts()
    },
    indexes() {
      this.groupIndexes = this.products.flatMap((group, groupIndex) => group.products
          .filter(product => this.indexes.includes(product.productInfo._id))
          .map(product => ({
            groupIndex,
            productId: product.productInfo._id,
          })))
    },
  },
  created() {
    this.$root.$refs.wishlist = this
    store.commit(UPDATE_SELECT_TYPE, MULTI_SELECT_PAGES.WISHLIST)
    this.filters.sort = this.selectedSortBy.value
    this.fetchShopProducts()
  },
  destroyed() {
    store.commit(UPDATE_SELECT_COUNT, 0)
    store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, false)
    analytics.track(
      this.isBrand
        ? constants.TRACKS.ACTIONS.BRAND_END_VIEWING_SELECTION
        : constants.TRACKS.ACTIONS.RETAILER_END_VIEWING_WISHLIST,
      formatObject(
        getSelectionOrWishlistMixpanelObj(this.wishlist, this.isBrand),
      ),
    )
  },
  methods: {
    onCheckboxChange(product, isChecked) {
      const productId = product.productInfo._id

      if (isChecked) {
        if (!this.indexes.includes(productId)) {
          this.indexes = [...this.indexes, productId]
          analytics.track(
            MULTI_SELECT_PRODUCTS.USER_SELECTS_PRODUCT_BY_CHECKBOX_IN_WISHLIST_PRODUCTS_PAGE,
            {
              product,
            },
          )
        }
      } else {
        this.indexes = this.indexes.filter(id => id !== productId)
        analytics.track(
          MULTI_SELECT_PRODUCTS.USER_DESELECTS_PRODUCT_BY_CHECKBOX_IN_WISHLIST_PRODUCTS_PAGE,
          {
            product,
          },
        )
      }
    },
    onClickShareWishlist() {
      this.$bvModal.show(`modal-share-wishlist-${this.wishlistId}`)
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_OPENS_SHARE_SELECTION_POPUP,
        { wishlist: this.wishlist },
      )
    },
    isProductInCart(product = null) {
      return product?.isInCart || product?.productInfo?.isInCart
    },
    async downloadSelectedWishlistProducts() {
      try {
        const payload = {
          downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
          respectiveId: this.collectionId,
          fileType: FILE_FORMATS.XLSX,
          productIds: this.indexes,
        }
        const params = {
          isOrderSheetDownload: true,
        }
        const res = await this.$store.dispatch(DOWNLOAD_COLLECTION, {
          payload,
          params,
        })
        apiToastSuccess(res.data.message)
        this.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
        this.unSelectAll()
      } catch (err) {
        apiToastWarning(err)
      }
    },
    onClickWishlistProduct(product) {
      this.$router.push({
        name: 'product-detail',
        params: {
          id: product.productInfo._id,
          collectionId: product.productInfo.collectionId,
        },
      })
      analytics.track(
        constants.TRACKS.PAGES.RETAILER_OPENS_WISHLIST_PRODUCT,
        formatObject(getSelectionOrWishlistMixpanelObj(product, false)),
      )
    },
    showTooltip(id, productId) {
      const productNameElement = document.getElementById(id)
      if (productNameElement && isEllipsisActive(productNameElement)) {
        this.tooltipId = productId
      }
    },
    onCompactToggleClick() {
      if (this.itemViewClass === PRODUCTS_VIEW.GRID_VIEW) {
        this.itemViewClass = PRODUCTS_VIEW.COMPACT_GRID_VIEW
      } else {
        this.itemViewClass = PRODUCTS_VIEW.GRID_VIEW
      }
    },
    async removeAllFromWishList() {
      const action = wishlistAction.REMOVE_IN_WISHLIST
      await this.reactToggleProductInWishlist(
        this.productList,
        this.indexes,
        action,
      )
      this.unSelectAll()
    },
    moveAllToCart() {
      this.addMultipleItemsCartActionClick({
        product: this.productList,
        indexes: this.indexes,
      })
      this.unSelectAll()
    },
    unSelectAll() {
      this.forceToggleSelect(false)
    },
  },

  setup(props, { root }) {
    const {
      parseProductImage,
      handleWishlistCartActionClick,
      toggleProductInWishlist,
      addMultipleItemsInCart,
    } = useEcommerceUi()
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const totalProducts = ref(0)
    const filters = ref({
      search: '',
      page: 0,
      limit: PRODUCTS_LIMIT,
    })
    const filterSetup = ref(false)
    const isLoaded = ref(false)
    const error = ref(null)
    const products = ref([])
    const filterOptions = ref({})
    let initialWholesalePriceMax
    let initialWholesalePriceMin
    let initialRetailPriceMax
    let initialRetailPriceMin
    const isWspHidden = ref(false)
    const requestStatus = ref(null)
    const groups = ref({})
    const selectedGroup = ref('None')
    let productsCount = 0

    const isLoading = ref(false)
    const loadingIcon = ref(null)
    const continueLoading = ref(true)
    const isSearchApplied = ref(false)
    const collectionId = ref(null)

    const deselectedProducts = ref([])
    const fetchQueryParams = ref({})

    const wishlist = ref({})
    const isReadOnly = ref(false)
    const isBrand = computed(() => root.$store.getters.isBrand)
    const wishlistId = computed(() => root.$route.params.wishlistId)
    const isSelectedAllProducts = computed(
      () => root.$store.state.shop.isSelectedAllProducts,
    )
    const selectCount = computed(() => root.$store.state.shop.selectCount)
    const isPerformingAction = ref(false)
    const wishlistBaseCurrency = ref('')
    const indexes = ref([])

    const getFetchParams = () => {
      const fetchParam = {
        page: filters.value.page,
        wishlistId: wishlistId.value,
      }
      for (const key in filters.value) {
        if (filters.value[key]) {
          if (Array.isArray(filters.value[key]) && filters.value[key].length) {
            fetchParam[key] = filters.value[key].join(',')
          } else {
            fetchParam[key] = filters.value[key]
          }
        }
      }
      fetchParam.groupBy = selectedGroup.value || 'None'

      return fetchParam
    }

    const { onToggleSelectAll, isSelectedAllOnScreen } = useSelections(
      products,
      getFetchParams,
      indexes,
    )

    const forceToggleSelect = forceSelect => {
      onToggleSelectAll({ forceSelect })
    }

    const forceSelectAll = () => {
      onToggleSelectAll(true)
    }

    const toggleSelectAllHandler = () => {
      onToggleSelectAll({
        selectEventTrack: () => analytics.track(
            MULTI_SELECT_PRODUCTS.USER_CLICKS_SELECT_ALL_PRODUCTS_IN_WISHLIST_PRODUCTS_PAGE,
            { wishlist: wishlist.value },
          ),
        unselectEvent: () => analytics.track(
            MULTI_SELECT_PRODUCTS.USER_CLICKS_DESELECT_ALL_PRODUCTS_IN_WISHLIST_PRODUCTS_PAGE,
            { wishlist: wishlist.value },
          ),
      })
    }

    const trackVisits = data => {
      if (isBrand.value) {
        analytics.track(
          constants.TRACKS.PAGES.BRAND_OPENS_SELECTION,
          formatObject(getSelectionOrWishlistMixpanelObj(data, isBrand.value)),
        )
        analytics.track(
          constants.TRACKS.ACTIONS.BRAND_START_VIEWING_SELECTION,
          formatObject(
            getSelectionOrWishlistMixpanelObj(wishlist.value, isBrand.value),
          ),
        )
        return
      }
      analytics.track(
        constants.TRACKS.PAGES.RETAILER_OPENS_WISHLIST_PRODUCTS,
        formatObject(getSelectionOrWishlistMixpanelObj(data, isBrand.value)),
      )
      analytics.track(
        constants.TRACKS.ACTIONS.RETAILER_START_VIEWING_WISHLIST,
        formatObject(
          getSelectionOrWishlistMixpanelObj(wishlist.value, isBrand.value),
        ),
      )
    }

    const processRequestFetchWishlistProducts = (fetchParam, isFilterApplied) => res => {
        const data = extractData(res)
        updateTotalProductsCount(data)

        if (shouldTrackVisits(isFilterApplied)) {
          trackVisits(data)
        }

        processPublicTokenData(data)

        wishlist.value = data

        if (!fetchParam.currency) {
          updateWishlistBaseCurrency(data)
        }

        store.commit(UPDATE_CURRENT_CHECKOUT_WISHLIST, data)
        updateWishlistState(data)
        isLoaded.value = true

        if (filters.value.page === 0) {
          productsCount = 0
        }

        processProductColors(data)
        updateTotalProducts(data)

        if (filters.value.page >= 1) {
          appendProducts(data)
        } else {
          products.value = data.products
        }

        isLoading.value = false

        updateBreadcrumb(data)
      }

    const extractData = res => (props.publicToken ? res?.data?.data.wishlist : res?.data?.data)

    const updateTotalProductsCount = data => {
      const count = data?.count
      root.$store.commit(UPDATE_TOTAL_PRODUCTS_COUNT, count)
    }

    const shouldTrackVisits = isFilterApplied => !isFilterApplied && !isSearchApplied.value

    const processPublicTokenData = data => {
      if (props.publicToken) {
        data.products = [
          {
            products: data.products.map(product => ({
              productInfo: product,
            })),
          },
        ]
        isReadOnly.value = true
      } else if (data?.isReadOnly) {
        isReadOnly.value = data.isReadOnly
      }
    }

    const updateWishlistBaseCurrency = data => {
      wishlistBaseCurrency.value = data?.products?.[0]?.products?.[0]?.productInfo?.currency
    }

    const updateWishlistState = data => {
      collectionId.value = data.collectionId
      isWspHidden.value = Boolean(data.isWspHidden)
      requestStatus.value = data.connectionRequestStatus || null
    }

    const processProductColors = data => {
      data.products.forEach(groupedProduct => {
        groupedProduct.products.forEach(product => {
          const colors = new Set()
          const productColors = []
          productsCount++
          product.productInfo.items.forEach(item => {
            if (!colors.has(item.color)) {
              colors.add(item.color)
              productColors.push({
                color: item.color,
                colorFamily: item.colorFamily,
              })
            }
          })
          product.colors = productColors
        })
      })
    }

    const updateTotalProducts = data => {
      totalProducts.value = data.count
      continueLoading.value = data.count > productsCount && data.products.length
    }

    const appendProducts = data => {
      const lastGroup = products.value[products.value.length - 1]
      const firstNewGroup = data.products[0]
      if (data.products.length && lastGroup._id === firstNewGroup._id) {
        lastGroup.products = lastGroup.products.concat(firstNewGroup.products)
        data.products.splice(0, 1)
      }
      products.value = [...products.value, ...data.products]
    }

    const updateBreadcrumb = data => {
      root.$route.meta.breadcrumb = root.$route.meta.breadcrumb.map(item => {
        if (item.dynamic && item.hasOwnProperty('wishlistName')) {
          item.wishlistName = data.name
        }
        return item
      })
    }

    const processErrorFetchWishlistProducts = err => {
      console.error(err)

      if (props.publicToken && err.response?.status === 404) {
        const errorMessage = err.response.data.message

        if (errorMessage === 'Wishlist is deleted by brand') {
          error.value = 'Selection is deleted by brand'
        } else if (errorMessage === 'No products found') {
          error.value = 'No products found'
        } else {
          error.value = 'Invalid link'
          apiToastError(err)
        }
      } else {
        apiToastError(err)
      }
    }

    const fetchWishlistProducts = async (
      fetchParam,
      isFilterApplied = false,
    ) => {
      let request = null
      fetchParam.wishlistId = wishlistId.value

      if (props.publicToken) {
        request = root.$store.dispatch(FETCH_WISHLIST_PRODUCTS_GUEST, {
          publicToken: props.publicToken,
          payload: fetchParam,
        })
      } else {
        request = root.$store.dispatch(FETCH_WISHLIST_PRODUCTS, fetchParam)
      }

      request
        .then(processRequestFetchWishlistProducts(fetchParam, isFilterApplied))
        .catch(processErrorFetchWishlistProducts)
        .finally(() => {
          isLoaded.value = true
        })
      if (isFilterApplied || isSearchApplied.value) {
        analytics.track(
          isBrand.value
            ? constants.TRACKS.ACTIONS.BRAND_APPLIES_FILTER_SLN
            : constants.TRACKS.ACTIONS.RETAILER_APPLIES_FILTER_WLS,
          formatObject(fetchParam),
        )
      }
      fetchQueryParams.value = fetchParam
    }
    // fetchShopProducts()
    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = newFilter => {
      let isFilterApplied = false

      if (newFilter && typeof newFilter === 'object') {
        isFilterApplied = true
        filters.value = newFilter
        filters.value.page = 0
        if (initialWholesalePriceMax === filters.value.wholesalePriceMax) delete filters.value.wholesalePriceMax
        if (initialWholesalePriceMin === filters.value.wholesalePriceMin) delete filters.value.wholesalePriceMin
        if (initialRetailPriceMax === filters.value.retailPriceMax) delete filters.value.retailPriceMax
        if (initialRetailPriceMin === filters.value.retailPriceMin) delete filters.value.retailPriceMin
      }
      const fetchParam = getFetchParams()
      fetchWishlistProducts(fetchParam, isFilterApplied)
    }

    const { removeProductFromWishlist } = useEcommerce()
    const removeProductFromWishlistClick = product => {
      removeProductFromWishlist(product.productInfo._id, wishlistId.value).then(
        res => {
          apiToastSuccess(res.data.message || 'Removed product from wishlist!')
          products.value.forEach(groupedProduct => {
            const productIndex = groupedProduct.products.findIndex(
              p => p.productInfo._id === product.productInfo._id,
            )
            if (productIndex > -1) {
              groupedProduct.products.splice(productIndex, 1)
            }
          })
          totalProducts.value -= 1
          if (totalProducts.value === 0) {
            filterOptions.value = []
          }
          analytics.track(
            isBrand.value
              ? constants.TRACKS.ACTIONS
                  .BRAND_REMOVES_SELECTED_ITEMS_FROM_SELECTION
              : constants.TRACKS.ACTIONS
                  .RETAILER_REMOVES_PRODUCTS_FROM_WISHLIST,
            formatObject(
              getSelectionOrWishlistMixpanelObj(wishlist.value, isBrand.value, {
                selectedProductsCount: 1,
              }),
            ),
          )
        },
      )
      const fetchParam = {}
      fetchWishlistFilters(fetchParam)
    }

    const reactToggleProductInWishlist = async (product, index, action) => {
      isPerformingAction.value = true
      let updatedProduct = await toggleProductInWishlist({
        product,
        wishlistId: wishlistId.value,
        action,
        wishlist: wishlist.value,
      })
      store.commit(UPDATE_SELECT_COUNT, 0)
      updatedProduct = updatedProduct?.products || updatedProduct
      if (updatedProduct.length) {
        updatedProduct.forEach(updatedProductData => {
          products.value.forEach(groupedProduct => {
            const productIndex = groupedProduct.products.findIndex(
              p => p.productId === updatedProductData.productId,
            )
            if (productIndex > -1) {
              groupedProduct.products.splice(productIndex, 1)
            }
          })
        })
        totalProducts.value -= updatedProduct.length
        if (totalProducts.value === 0) {
          filterOptions.value = []
        }
      }
      isPerformingAction.value = false
    }

    const addMultipleItemsCartActionClick = async ({ product, indexes }) => {
      await addMultipleItemsInCart({
        product,
        selectedProductsIds: indexes,
      })
      store.commit(UPDATE_SELECT_COUNT, 0)
      analytics.track(
        constants.TRACKS.ACTIONS.RETAILER_MOVES_FROM_WISHLIST_TO_CART,
        formatObject(getSelectionOrWishlistMixpanelObj(wishlist.value, false)),
      )
    }

    // setup filters
    const fetchWishlistFilters = async fetchParam => {
      addWishlistIdToFetchParam(fetchParam)

      const request = createFetchWishlistFiltersRequest(fetchParam)

      await handleWishlistFiltersResponse(request)
    }

    const addWishlistIdToFetchParam = fetchParam => {
      if (wishlistId.value) {
        fetchParam.wishlistId = wishlistId.value
      }
    }

    const createFetchWishlistFiltersRequest = fetchParam => {
      if (props.publicToken) {
        return root.$store.dispatch(FETCH_WISHLIST_FILTERS_GUEST, {
          publicToken: props.publicToken,
          payload: fetchParam,
        })
      } else {
        return root.$store.dispatch(FETCH_WISHLIST_FILTERS, fetchParam)
      }
    }

    const handleWishlistFiltersResponse = async request => {
      await request.then(res => {
        filterOptions.value = res.data.data[0] || {}

        processFilterOptions()

        filterOptions.value.retailPrice = [
          filterOptions.value.retailPriceMin,
          filterOptions.value.retailPriceMax,
        ]
        filterOptions.value.wholesalePrice = [
          filterOptions.value.wholesalePriceMin,
          filterOptions.value.wholesalePriceMax,
        ]
        initialWholesalePriceMax = filterOptions.value.wholesalePriceMax
        initialWholesalePriceMin = filterOptions.value.wholesalePriceMin
        initialRetailPriceMax = filterOptions.value.retailPriceMax
        initialRetailPriceMin = filterOptions.value.retailPriceMin
        groups.value = filterOptions.value.groupBy
        filterSetup.value = true
      })
    }

    const processFilterOptions = () => {
      for (const key in filterOptions.value) {
        if (filterOptions.value.hasOwnProperty(key)) {
          filters.value[key] = []
          if (key.includes('PriceMax')) {
            filterOptions.value[key] = Math.round(filterOptions.value[key]) + 1
          }
          if (key.includes('PriceMin')) {
            filterOptions.value[key] = Math.round(filterOptions.value[key]) - 1
          }
        }
      }
    }

    if (!filterSetup.value) {
      const fetchParam = {
        page: 0,
      }
      fetchWishlistFilters(fetchParam)
    }

    const filterByCurrency = (currency = '') => {
      if (!currency) {
        delete filters.value?.currency
      } else {
        filters.value.currency = currency
        filters.value.page = 0
      }
      fetchShopProducts()
    }

    const searchProducts = debounce(() => {
      isSearchApplied.value = true
      filters.value.page = 0
      fetchShopProducts()
    }, 1000)

    watch([selectedGroup], () => {
      filters.value.page = 0
      fetchShopProducts()
    })
    const closeSideBar = () => {
      mqShallShowLeftSidebar.value = false
    }
    const onScrollLoader = () => {
      if (!isLoading.value && elementInViewport(loadingIcon.value)) {
        isLoading.value = true
        filters.value.page++
        fetchShopProducts()
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', onScrollLoader)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScrollLoader)
    })

    // wishlist actions
    return {
      wishlist,
      isLoaded,
      products,
      wishlistId,
      notFoundImg: require('@/assets/images/pages/error.svg'),
      // UI
      addMultipleItemsCartActionClick,
      parseProductImage,
      handleWishlistCartActionClick,
      removeProductFromWishlistClick,
      toggleProductInWishlist,
      reactToggleProductInWishlist,
      addMultipleItemsInCart,
      formatCurrency,
      mqShallShowLeftSidebar,
      totalProducts,
      filters,
      filterOptions,
      filterSetup,
      isWspHidden,
      requestStatus,
      fetchShopProducts,
      closeSideBar,
      groups,
      selectedGroup,
      continueLoading,
      loadingIcon,
      searchProducts,
      collectionId,
      isReadOnly,
      isSelectedAllProducts,
      selectCount,
      deselectedProducts,
      isPerformingAction,
      filterByCurrency,
      wishlistBaseCurrency,
      indexes,
      forceSelectAll,
      onToggleSelectAll: toggleSelectAllHandler,
      isSelectedAllOnScreen,
      forceToggleSelect,
      error,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-shop.scss';

.content.app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 1rem 0;
}

.error-text {
  color: $danger;
  font-size: 2rem;
}
</style>
